import React from "react"

import SEO from "../components/seo"
import Navbar from "../components/navbar"
import { Footer } from "../components/footer"

const About = () => {
  return (
    <>
      <Navbar />
      <SEO title="About me" />
      <section className="section-wrapper bg-light-beige">
        <div className="section about-section column-direction">
          <h2 className="about-greeting about-greeting-long">
            <span className="gradient-text">Hey, nice to see you! </span>
            <span role="img" aria-label="wave-hand-emoji">
              👋
            </span>
          </h2>
          <h2 className="about-greeting about-greeting-medium">
            <span className="gradient-text">Hey, welcome! </span>
            <span role="img" aria-label="wave-hand-emoji">
              👋
            </span>
          </h2>
          <h2 className="about-greeting about-greeting-short">
            <span className="gradient-text">Hey! </span>
            <span role="img" aria-label="wave-hand-emoji">
              👋
            </span>
          </h2>
          <div className="blog-post-body">
            <p>
              My name is Ségolène and I'm a Software Engineer based in <span className='line-through'>Paris</span> the South of France{" "}
              <span role="img" aria-label="french-flag">
                🇫🇷
              </span>
              . <br />I currently work at{" "}
              <a
                className="docto-link link"
                href="https://www.doctolib.fr/"
                target="_blank"
				rel="noreferrer"
              >
                Doctolib
              </a>
              , where we build innovative products for Doctors with React & Ruby on
              Rails.
            </p>
            <p>
              I studied law & management and worked as a Product Manager in a
              GreenTech start-up, where I grew curious about what was going on
              behind the scenes and decided to learn coding.
            </p>
            <p>
              I first did a web development bootcamp in January 2018 and it's
              been a turning point in my life. I loved it soooo much that I
              decided to enter{" "}
              <a
                className="ecole-link link"
                href="https://www.42.fr/"
                target="_blank"
				rel="noreferrer"
              >
                Ecole 42
              </a>{" "}
              to gain in-depth knowledge of Computer Science, where I
              specialized in web development.
            </p>
            <p>
              I have concurrently been working as a freelance web developer and
              teaching code to children & digital marketing students.
            </p>
            <p>
              In my free time, I spend looots of time on{" "}
              <a
                className="codepen-link link"
                href="https://codepen.io/segolene-alquier"
                target="_blank"
				rel="noreferrer"
              >
                Codepen
              </a>{" "}
              creating art with CSS & Javascript.
            </p>
            <p>
              When I am not behind a screen, I love to:
              <ul>
                <li>
                  <span role="img" aria-label="french-flag">
                    🧘🏻‍♀️
                  </span>{" "}
                  spend time on my yoga mat
                </li>
                <li>
                  <span role="img" aria-label="french-flag">
                    🥦
                  </span>{" "}
                  cook yummy veggie food
                </li>
                <li>
                  <span role="img" aria-label="french-flag">
                    🎤
                  </span>{" "}
                  play the guitar and ukulele, and sing along
                </li>
              </ul>
            </p>
            <hr />
            <p>
              I am not looking for a new full-time job but I am available for
              writing, speaking and freelance opportunities!
            </p>
            <p>
              <span role="img" aria-label="love-letter">
                💌
              </span>{" "}
              Feel free to{" "}
              <a className="email-link" href="mailto:segolene.alquier@gmail.com">
                reach out to me
              </a>
              .
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
export default About
